@import 'variables';

.small-tooltip {
  display: inline-block;
  border-radius: 6px;
  position: absolute;
  background: $grey-06;
  color: $white;
  max-width: 202px;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  z-index: 2000;
}
