@import 'font-families';
@import 'icons';
@import 'variables';
@import 'mixins';
@import 'tooltips';
@import 'typography';
@import 'font-weight';
@import '@angular/cdk/overlay-prebuilt.css';

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  color: inherit;
}

a {
  color: $color-text-link;
  cursor: pointer;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;

  &.reversed {
    color: $color-text-link-reversed;
  }

  &:hover {
    color: $color-text-link-hover;
    &.reversed {
      color: $color-text-link-hover-reversed;
    }
  }

  &:active {
    color: $color-text-link-active;
    transition: none;
    &.reversed {
      color: $color-text-link-active-reversed;
    }
  }

  &:focus-visible {
    border: 4px solid $color-border-focus;
    padding: 0 4px;
    border-radius: 4px;
  }
}

// would love to just make these 'default' global styles but that would be a massively breaking change
// so for now making consumers 'opt-in' to this by using the 'semantic' class.
h1.semantic {
  @include heading-xxxl();
}

h2.semantic {
  @include heading-xxl();
}

h3.semantic {
  @include heading-xl();
}

h4.semantic {
  @include heading-lg();
}

h5.semantic {
  @include heading-md();
}

h6.semantic {
  @include heading-sm();
}

p.semantic {
  @include copy();
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.semantic {
    margin: 0;
  }
}

// this is how we interpret / implement the 'paragraph spacing' specifications in figma for typography
// I didn't bother with h1-h3 since those are hardly ever used as paragraphs. Just do that yourself
h4.semantic:has(+ h4.semantic) {
  margin-bottom: 20px;
}

h5.semantic:has(+ h5.semantic),
h6.semantic:has(+ h6.semantic) {
  margin-bottom: 16px;
}

p.semantic:has(+ p.semantic) {
  margin-bottom: 12px;
}
