/** material icons **/

@import 'material-icons/iconfont/material-icons.css';

/** DEPRECATED old icon system **/

/* Generated by Glyphter (http://www.glyphter.com) on  Fri Jul 31 2020*/
@font-face {
  font-family: 'Symetra-Icons';
  src: url('../fonts/Symetra-Icons.eot');
  src:
    url('../fonts/Symetra-Icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Symetra-Icons.woff') format('woff'),
    url('../fonts/Symetra-Icons.ttf') format('truetype'),
    url('../fonts/Symetra-Icons.svg#Symetra-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'Symetra-Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-alert:before {
  content: '\0041';
}
.icon-arrow-down:before {
  content: '\0042';
}
.icon-arrow-left:before {
  content: '\0043';
}
.icon-arrow-right:before {
  content: '\0044';
}
.icon-arrow-up:before {
  content: '\0045';
}
.icon-business-card:before {
  content: '\0046';
}
.icon-calendar:before {
  content: '\0047';
}
.icon-chat:before {
  content: '\0048';
}
.icon-check-circle:before {
  content: '\0049';
}
.icon-check:before {
  content: '\004a';
}
.icon-chevron-double-left:before {
  content: '\004b';
}
.icon-chevron-double-right:before {
  content: '\004c';
}
.icon-chevron-down:before {
  content: '\004d';
}
.icon-chevron-left:before {
  content: '\004e';
}
.icon-chevron-right:before {
  content: '\004f';
}
.icon-chevron-up:before {
  content: '\0050';
}
.icon-close:before {
  content: '\0051';
}
.icon-cloud-download:before {
  content: '\0052';
}
.icon-delete:before {
  content: '\0053';
}
.icon-document:before {
  content: '\0054';
}
.icon-download:before {
  content: '\0055';
}
.icon-edit-off:before {
  content: '\0056';
}
.icon-edit:before {
  content: '\0057';
}
.icon-envelope:before {
  content: '\0058';
}
.icon-error:before {
  content: '\0059';
}
.icon-external-link:before {
  content: '\005a';
}
.icon-facebook:before {
  content: '\0061';
}
.icon-fax:before {
  content: '\0062';
}
.icon-filter:before {
  content: '\0063';
}
.icon-globe:before {
  content: '\0064';
}
.icon-Information:before {
  content: '\0065';
}
.icon-instagram:before {
  content: '\0066';
}
.icon-linkedin:before {
  content: '\0067';
}
.icon-lock:before {
  content: '\0068';
}
.icon-lock-open:before {
  content: '\0030';
}
.icon-mailbox:before {
  content: '\0069';
}
.icon-menu:before {
  content: '\006a';
}
.icon-minus:before {
  content: '\006b';
}
.icon-office-building:before {
  content: '\006c';
}
.icon-phone:before {
  content: '\006d';
}
.icon-plus:before {
  content: '\006e';
}
.icon-search:before {
  content: '\006f';
}
.icon-security:before {
  content: '\0070';
}
.icon-share:before {
  content: '\0071';
}
.icon-sort-arrow-down:before {
  content: '\0072';
}
.icon-sort-arrow-up:before {
  content: '\0073';
}
.icon-sort-arrows:before {
  content: '\0074';
}
.icon-sort:before {
  content: '\0075';
}
.icon-tool-tip:before {
  content: '\0076';
}
.icon-twitter:before {
  content: '\0077';
}
.icon-upload:before {
  content: '\0078';
}
.icon-view:before {
  content: '\0079';
}
.icon-warning:before {
  content: '\007a';
}
