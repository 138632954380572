// grey
$color-grey-50: #fafafa; // rgb(250,250,250)
$color-grey-100: #f8f8f8; // rgb(248,248,248)
$color-grey-200: #ebebeb; // rgb(235,235,235)
$color-grey-300: #dbdbdb; // rgb(219,219,219)
$color-grey-400: #cccccc; // rgb(204,204,204)
$color-grey-500: #b3b3b3; // rgb(179,179,179)
$color-grey-600: #7d7d7d; // rgb(125,125,125)
$color-grey-700: #525252; // rgb(82,82,82)
$color-grey-750: #424242; // rgb(66,66,66)
$color-grey-800: #2b2b2b; // rgb(43,43,43)
$color-grey-900: #212121; // rgb(33,33,33)

// blue / brand
$color-blue-100: #edf7fe; // rgb(237,247,254)
$color-blue-200: #dbeffe; // rgb(219,239,254)
$color-blue-300: #93cefc; // rgb(147,206,252)
$color-blue-400: #4cadfa; // rgb(76,173,250)
$color-blue-500: #078bf5; // rgb(7,139,245)
$color-blue-600: #0677d1; // rgb(6,119,209)
$color-blue-700: #05599d; // rgb(5,89,157)
$color-blue-800: #033c69; // rgb(3,60,105)

// also brand aliases
$color-brand-100: $color-blue-100;
$color-brand-200: $color-blue-200;
$color-brand-300: $color-blue-300;
$color-brand-400: $color-blue-400;
$color-brand-500: $color-blue-500;
$color-brand-600: $color-blue-600;
$color-brand-700: $color-blue-700;
$color-brand-800: $color-blue-800;

// teal
$color-teal-100: #e5fffc; // rgb(229,255,252)
$color-teal-200: #dbfffa; // rgb(219,255,250)
$color-teal-300: #90f0e4; // rgb(144,240,228)
$color-teal-400: #32cfbc; // rgb(50,207,188)
$color-teal-500: #00b09b; // rgb(0,176,155)
$color-teal-600: #00907f; // rgb(0,144,127)
$color-teal-700: #007668; // rgb(0,118,104)
$color-teal-800: #005c51; // rgb(0,92,81)

// green
$color-green-100: #f2ffed; // rgb(242,255,237)
$color-green-200: #dffcd4; // rgb(223,252,212)
$color-green-300: #a6f18c; // rgb(166,241,140)
$color-green-400: #7edb5e; // rgb(126,219,94)
$color-green-500: #59c433; // rgb(126,219,94)
$color-green-600: #2dab19; // rgb(45,171,25)
$color-green-700: #008a00; // rgb(0,138,0)
$color-green-800: #006900; // rgb(0,105,0)

// yellow
$color-yellow-100: #fff9e1; // rgb(255, 249, 225)
$color-yellow-200: #fef3c1; // rgb(254, 243, 193)
$color-yellow-300: #feea8e; // rgb(254, 234, 142)
$color-yellow-400: #fddf59; // rgb(253, 223, 89)
$color-yellow-500: #fdcf03; // rgb(253, 207, 3)
$color-yellow-600: #ffc412; // rgb(255, 196, 18)
$color-yellow-700: #edab11; // rgb(237, 171, 17)
$color-yellow-800: #d48a00; // rgb(212, 138, 0)

// pink
$color-pink-100: #ffedfb; // rgb(255, 237, 251)
$color-pink-200: #ffcff5; // rgb(255, 207, 245)
$color-pink-300: #ffb2f0; // rgb(255, 178, 240)
$color-pink-400: #ed58ca; // rgb(237, 88, 202)
$color-pink-500: #e800b2; // rgb(232, 0, 178)
$color-pink-600: #cc008f; // rgb(204, 0, 143)
$color-pink-700: #a30072; // rgb(163, 0, 114)
$color-pink-800: #7d0057; // rgb(125, 0, 87)

// red
$color-red-100: #ffefed; // rgb(255, 237, 237)
$color-red-200: #ffd7d1; // rgb(255, 215, 209)
$color-red-300: #ffad9e; // rgb(255, 173, 158)
$color-red-400: #ff6f59; // rgb(255, 111, 89)
$color-red-500: #f0482e; // rgb(240, 72, 46)
$color-red-600: #c72208; // rgb(199, 34, 8)
$color-red-700: #991500; // rgb(153, 21, 0)
$color-red-800: #751000; // rgb(117, 16, 0)

// orange
$color-orange-100: #fff7f0; // rgb(255, 247, 240)
$color-orange-200: #ffead9; // rgb(255, 234, 217)
$color-orange-300: #fecca3; // rgb(254, 204, 163)
$color-orange-400: #ffa861; // rgb(255, 168, 97)
$color-orange-500: #ff8929; // rgb(255, 137, 41)
$color-orange-600: #ff6a00; // rgb(255, 106, 0)
$color-orange-700: #db5000; // rgb(219, 80, 0)
$color-orange-800: #ab4100; // rgb(171, 65, 0)

// purple
$color-purple-100: #faedff; // rgb(250, 237, 255)
$color-purple-200: #eac3f7; // rgb(234, 195, 247)
$color-purple-300: #df99f7; // rgb(223, 153, 247)
$color-purple-400: #c24deb; // rgb(194, 77, 235)
$color-purple-500: #aa10e0; // rgb(170, 16, 224)
$color-purple-600: #8700b8; // rgb(135, 0, 184)
$color-purple-700: #710099; // rgb(113, 0, 153)
$color-purple-800: #590078; // rgb(89, 0, 120)

// white
$color-white: #ffffff; // rgb(255,255,255)

// black
$color-black: #000000; // rgb(0,0,0)

// gradients
$color-gradient-lightblue: linear-gradient(90deg, #4cadfa 2.04%, #0677d1 98.66%);
$color-gradient-darkblue: linear-gradient(90deg, #0677d1 2.04%, #033c69 98.66%);
$color-gradient-greenblue: linear-gradient(90deg, #b8de75 2.04%, #4cadfa 98.66%);
