$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

@mixin light {
  font-weight: $font-weight-light !important;
}
@mixin semibold {
  font-weight: $font-weight-semibold !important;
}
@mixin bold {
  font-weight: $font-weight-bold !important;
}

.light {
  @include light();
}

.semibold {
  @include semibold();
}

.bold {
  @include bold();
}
