@import 'color-tokens';
@import 'font-weight';

$font-family: 'Assistant', sans-serif;

@mixin typography-base {
  font-family: $font-family;
  font-size: 16px;
  line-height: 20px;
  color: $color-text-default;
  font-weight: $font-weight-regular;
  font-style: normal;
}

@mixin copy {
  @include typography-base();
}

@mixin copy-disclosure {
  @include typography-base();
  font-size: 13px;
  line-height: 16px;
}

@mixin copy-sm {
  @include typography-base();
  font-size: 12px;
  line-height: 16px;
}

@mixin copy-xs {
  @include typography-base();
  font-size: 9px;
  line-height: 12px;
}

@mixin heading {
  font-family: $font-family;
}

@mixin heading-xxxl {
  @include heading();
  font-size: 67px;
  line-height: 80px;
}

@mixin heading-xxl {
  @include heading();
  font-size: 52px;
  line-height: 64px;
}

@mixin heading-xl {
  @include heading();
  font-size: 41px;
  line-height: 52px;
}

@mixin heading-lg {
  @include heading();
  font-size: 32px;
  line-height: 40px;
}

@mixin heading-md {
  @include heading();
  font-size: 25px;
  line-height: 32px;
}

@mixin heading-sm {
  @include heading();
  font-size: 20px;
  line-height: 26px;
}

.heading {
  @include heading();
  &-xxxl {
    @include heading-xxxl();
  }
  &-xxl {
    @include heading-xxl();
  }
  &-xl {
    @include heading-xl();
  }
  &-lg {
    @include heading-lg();
  }
  &-md {
    @include heading-md();
  }
  &-sm {
    @include heading-sm();
  }
}

.copy {
  @include copy();

  &-sm {
    @include copy-sm();
  }

  &-xs {
    @include copy-xs();
  }

  &-disclosure {
    @include copy-disclosure();
  }
}

// legacy mixins and global styles / unsupported
@mixin normal {
  font-size: 16px;
  line-height: 21px;
}

@mixin text {
  @include normal();
  font-family: $font-family;
  font-weight: normal;
  color: $font-color-primary;
}
@mixin text-sm {
  @include text();
  font-size: 13px;
  line-height: 16px;
}
@mixin text-xs {
  @include text();
  font-size: 13px;
  line-height: 16px;
}

.text {
  @include text();

  &-sm {
    @include text-sm();
  }
  &-xs {
    @include text-xs();
  }
}

.text-link {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: underline;
  user-select: text;

  color: $color-text-link;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;

  &.reversed {
    color: $color-text-link-reversed;
  }

  &:hover {
    color: $color-text-link-hover;
    &.reversed {
      color: $color-text-link-hover-reversed;
    }
  }

  &:active {
    color: $color-text-link-active;
    transition: none;
    &.reversed {
      color: $color-text-link-active-reversed;
    }
  }

  &:focus-visible {
    border: 4px solid $color-border-focus;
    padding: 0 4px;
    border-radius: 4px;
  }
}
