/* You can add global styles to this file, and also import other style files */
@import '@dxo/ng-swift-ui/css/variables';
@import '@dxo/ng-swift-ui/css/mixins';
@import '@dxo/ng-swift-ui/css/core.scss';
html,
body {
  background: $color-gradient-darkblue;
  box-sizing: border-box;
  font-family: $font-family, sans-serif;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: $color-text-link;
  cursor: pointer;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;

  &.reversed {
    color: $color-text-link-reversed;
  }

  &:hover {
    color: $color-text-link-hover;
    &.reversed {
      color: $color-text-link-hover-reversed;
    }
  }

  &:active {
    color: $color-text-link-active;
    transition: none;
    &.reversed {
      color: $color-text-link-active-reversed;
    }
  }

  &:focus-visible {
    outline: none;
    border: 4px solid $color-border-focus;
    padding: 0 4px;
    border-radius: 4px;
  }
}

// design guidelines say all of the text inputs should be as big as they can be.
.dxo-input-container {
  width: unset !important;
}

// design guidelines say all "regular text" has to be $grey-07.
p,
:not(button.link-button) > div {
  color: $grey-07;
}
